import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import WebinarVideo from "./../screens/webinar-replay/webinar-video"
import BodyLanding from "./../screens/webinar-replay/2023-10-27-fish/body-landing"

const WebinarView = () => {

	return (
		<React.Fragment>
			<WebinarVideo
				videoTitle="Updates on Influenza, COVID-19 and RSV: Trends and Treatments"
				videoDisplayTitle="Updates on Influenza, COVID-19 and RSV: Trends and Treatments"
				vimeoLink="https://player.vimeo.com/video/884097917?h=59f3657a66" 
				placeholderImage="2023-10-27-fish-webinar-replay.jpg"
				localStorageID="2023-10-27-fish-webinar-replay" 
				pageSlug="/webinar-replay/2023-10-27-fish/"
				publicView
			/>
			<BodyLanding pageName="download" localStorageID="submissionGuid" />
		</React.Fragment>
	)
}

export default WebinarView

export const Head = () => (
  <Seo
  title="Webinar: Updates on Influenza, COVID-19 and RSV: Trends and Treatments"
  description="Updates on Influenza, COVID-19 and RSV: Trends and Treatments"
  image="/meta/webinar-fish-2023-10-27.jpg"
/>
)
